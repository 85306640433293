/* eslint-disable @typescript-eslint/ban-ts-comment */
// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as ReactRouterDOM from "react-router-dom";
import * as ReactRouter from "react-router";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import {
  AnalyticsProvider,
  pieLightTheme,
  SettingsProvider
} from "@pie/components";
import {
  ThemeProvider,
  createTheme,
  createGenerateClassName,
  StylesProvider
} from "@material-ui/core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { history, store } from "./store/createStore";
import { AppBootstrap } from "./components/AppBootstrap/AppBootstrap";
import { PieCrustProvider } from "./externals";
import "./index.css";
// Needed for PaymentDrawer to load proper css files
import "@pie/shared-payment-drawer/dist/style.css";

const rootElement = document.getElementById("piecrust-root");

// @ts-ignore
export const theme = createTheme(pieLightTheme);

export const pieClassNameGenerator = createGenerateClassName({
  seed: "piecrust"
});

declare global {
  interface Window {
    ReactRouterDOM: typeof ReactRouterDOM;
    ReactRouter: typeof ReactRouter;
    datadog: {
      datadogRum: typeof datadogRum;
    };
  }
}

window.ReactRouterDOM = ReactRouterDOM;
window.ReactRouter = ReactRouter;
window.datadog = { datadogRum };

type CookieData = {
  cookieKeys: string[];
  cookieSizes: number[];
  duplicateCognitoTokens: boolean;
  totalCookieSize: number;
};

function getCookieData(): CookieData {
  const cookieKeys: string[] = [];
  const cookieSizes: number[] = [];
  let idTokenCount = 0;
  let totalCookieSize = 0;

  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trim();
    const eqPos = cookie.indexOf("=");

    if (eqPos > -1) {
      const key = cookie.substr(0, eqPos);
      cookieKeys.push(key);
      if (
        key.startsWith("CognitoIdentityServiceProvider") &&
        key.endsWith("idToken")
      ) {
        idTokenCount++;
      }

      // Calculate the size of the entire cookie string in bytes, accounting for UTF-16 low/high surrogates.
      // Each character can be from 1 byte (ASCII) up to 4 bytes (UTF-16 surrogate pairs).
      let size = 0;

      for (const char of cookie) {
        const code = char.codePointAt(0);
        if (code && code <= 0x7f) {
          size += 1;
        } else if (code && code <= 0x7ff) {
          size += 2;
        } else if (code && code <= 0xffff) {
          size += 3;
        } else {
          size += 4;
        }
      }

      // The size we calculate always seems to be 1 byte larger
      // than what the browser reports, so we subtract it by 1.
      size -= 1;
      cookieSizes.push(size);
      totalCookieSize += size;
    }
  }

  return {
    cookieKeys,
    cookieSizes,
    duplicateCognitoTokens: idTokenCount >= 2,
    totalCookieSize
  };
}

const renderApp = (Component: React.FC): void => {
  ReactDOM.render(
    <Provider store={store}>
      <AnalyticsProvider>
        <ConnectedRouter history={history}>
          <StylesProvider generateClassName={pieClassNameGenerator}>
            <ThemeProvider {...{ theme }}>
              <SettingsProvider
                onLoadError={console.warn}
                defaultSettings={{}}
                url="/appsettings.json"
                onSuccess={async ({ datadogConfiguration }): Promise<void> => {
                  if (datadogConfiguration) {
                    datadogRum.init({
                      ...datadogConfiguration,
                      trackUserInteractions: true,
                      trackInteractions: true,
                      allowedTracingUrls: [
                        /https:\/\/(.*\.)?pieinsurance\.com($|\/.*)/
                      ],
                      enableExperimentalFeatures: ["feature-flags"],
                      trackSessionAcrossSubdomains: true,
                      sampleRate: 100,
                      replaySampleRate: 100
                    });
                    // Send all logs to datadog, not just errors
                    datadogLogs.init({
                      ...datadogConfiguration,
                      forwardErrorsToLogs: true,
                      sessionSampleRate: 100
                    });

                    datadogRum.startSessionReplayRecording();

                    const {
                      cookieKeys,
                      cookieSizes,
                      duplicateCognitoTokens,
                      totalCookieSize
                    } = getCookieData();

                    datadogRum.addAction("Review Cookies", {
                      version: "1.0.1",
                      cookieKeys,
                      cookieSizes,
                      totalCookieSize
                    });

                    if (duplicateCognitoTokens) {
                      datadogRum.addAction("Duplicate Cognito Tokens", {
                        cookieKeys
                      });
                    }

                    try {
                      // typescript does not explicitly support APIs that are not in all major browsers
                      // @ts-ignore
                      const cookies = await cookieStore.getAll();
                      datadogRum.addAction("Cookies", {
                        cookies: cookies
                      });
                    } catch (error) {
                      console.warn("cookieStore not available", error);
                    }
                  }
                }}
              >
                <PieCrustProvider>
                  <Component />
                </PieCrustProvider>
              </SettingsProvider>
            </ThemeProvider>
          </StylesProvider>
        </ConnectedRouter>
      </AnalyticsProvider>
    </Provider>,
    rootElement
  );
};

renderApp(AppBootstrap);

if (module.hot) {
  module.hot.accept("./components/AppBootstrap/AppBootstrap", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const AppBootstrap = require("./components/AppBootstrap/AppBootstrap")
      .AppBootstrap;
    renderApp(AppBootstrap);
  });
}
