import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PieCrustStateKey } from "../../externals";
import {
  AvailableAgenciesState,
  Agency,
  Agent
} from "../../externals/store/availableAgencies/types";
import { authUserActions, SetAuthUserAction } from "../authUser";

type GetAgenciesSuccessfulAction = PayloadAction<GetAgenciesSuccessfulPayload>;
interface GetAgenciesSuccessfulPayload {
  authUserAgency: Agency;
  availableAgencies: Agency[];
}

export type SetSelectedAgencyAction = PayloadAction<Agency>;
export type SetAgencyUsers = PayloadAction<{
  agencyId: string;
  agents: Agent[];
}>;

const getAvailableAgencies = createAction(
  `${PieCrustStateKey.AVAILABLE_AGENCIES}/getAvailableAgencies`
);

const initialState: AvailableAgenciesState = {
  collection: []
};

const collectionContainsAuthUserAgency = (
  collection: Agency[],
  partnerId: string | undefined
): boolean =>
  Boolean(partnerId) && collection.some(agency => agency.id === partnerId);

const availableAgencies = createSlice({
  name: PieCrustStateKey.AVAILABLE_AGENCIES,
  initialState,
  reducers: {
    getAvailableAgenciesSuccessful: (
      state: AvailableAgenciesState,
      action: GetAgenciesSuccessfulAction
    ): AvailableAgenciesState => {
      const { authUserAgency, availableAgencies } = action.payload;
      const collection = Object.values(authUserAgency).every(value => !value)
        ? availableAgencies
        : [authUserAgency, ...availableAgencies];
      return {
        ...state,
        collection
      };
    },
    setSelectedAgencyId: (
      state: AvailableAgenciesState,
      action: SetSelectedAgencyAction
    ): AvailableAgenciesState => ({
      ...state,
      selectedAgencyId: action.payload.id
    }),
    setAgencyUsers: (
      state: AvailableAgenciesState,
      { payload: { agencyId, agents } }: SetAgencyUsers
    ) => {
      const { collection } = state;
      const selectedAgencyIndex = collection.findIndex(
        agency => agency.id === agencyId
      );
      collection[selectedAgencyIndex].agents = agents;
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        authUserActions.setAuthUser,
        (state: AvailableAgenciesState, action: SetAuthUserAction) => {
          const { partner: partnerId, partnerName } = action.payload;
          const { collection } = state;
          if (
            !partnerId ||
            !partnerName ||
            collectionContainsAuthUserAgency(collection, partnerId)
          ) {
            return state;
          }
          return {
            ...state,
            selectedAgencyId: partnerId,
            collection: [
              {
                id: partnerId,
                internalAgencyName: partnerName
              },
              ...collection
            ]
          };
        }
      )
      .addCase(authUserActions.clearAuthUser, () => initialState);
  }
});

export const availableAgenciesActions = {
  ...availableAgencies.actions,
  getAvailableAgencies: getAvailableAgencies
};

export const availableAgenciesActionTypes = {
  setSelectedAgencyId: availableAgenciesActions.setSelectedAgencyId.type,
  setAgencyUsers: availableAgenciesActions.setAgencyUsers.type,
  getAvailableAgencies: availableAgenciesActions.getAvailableAgencies.type,
  getAvailableAgenciesSuccessful:
    availableAgenciesActions.getAvailableAgenciesSuccessful.type
};

export const availableAgenciesReducer = availableAgencies.reducer;
