import { AuthUser } from "@pie/components";
import * as LDClient from "launchdarkly-js-client-sdk";
import { datadogLogs } from "@datadog/browser-logs";

export interface CanaryFlag {
  sidebar?: boolean;
  rewrites: {
    [path: string]: boolean;
  };
  "micro-ui-wrapper": boolean;
}

export const logInfo = (message: string): void => {
  datadogLogs.logger.info(`[PieCrust-Canary-Routing]: ${message}`);
};

export const logError = (errorMessage: string): void => {
  const fullMessage = `[PieCrust-Canary-Routing]: Error... ${errorMessage}`;
  console.error(fullMessage);
  datadogLogs.logger.error(fullMessage);
};

export const getPathNames = (path: string): string[] => {
  let newPath = path;
  // Remove leading slash
  if (path.startsWith("/")) {
    newPath = path.substring(1);
  }
  // Remove trailing slash
  if (path.endsWith("/")) {
    newPath = newPath.substring(0, newPath.length - 1);
  }
  if (newPath === "") {
    return [];
  }
  return newPath.split("/");
};

export const fetchFlags = async (
  clientId: string,
  user: AuthUser
): Promise<Record<string, CanaryFlag> | null> => {
  if (!user.subject) {
    return null;
  }
  logInfo(
    `Attempting to fetch all canary flags. user: ${user.subject || "unknown"}`
  );
  const context: LDClient.LDContext = {
    kind: "multi",
    user: {
      key: user.subject
    },
    partner: {
      key: user.partner,
      name: user.partnerName
    }
  };
  logInfo(`Fetching flags with context: ${JSON.stringify(context)}`);
  const client = LDClient.initialize(clientId, context, {
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (flagKey: string, flagDetail: LDClient.LDEvaluationDetail) => {
          window.datadog.datadogRum.addFeatureFlagEvaluation(
            flagKey,
            flagDetail.value
          );
        }
      }
    ]
  });
  await client.waitUntilReady();
  const flags = client.allFlags();
  datadogLogs.logger.info(
    `[PieCrust-Canary-Routing]: Retrieved all flags. ${JSON.stringify(flags)}`
  );
  return flags;
};
