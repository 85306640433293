import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "@pie/components";

import { SESSION_INACTIVITY_TIMEOUT } from "./constants";
import { notificationsActions } from "../../externals";

export const eventsToListenTo = ["mousemove", "keyup"];

export const InactivityMessage =
  "You’ve been signed out due to inactivity. Please sign in to reactivate your session.";

export const ActivityMonitor: React.FC = () => {
  const timer = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const { logout, isAuthenticated } = useAuth();
  const logOutAndNotify = (): void => {
    logout();
    dispatch(
      notificationsActions.addNotification({
        message: InactivityMessage,
        type: "error"
      })
    );
  };
  const stopTimer = (): void => {
    timer.current && clearTimeout(timer.current);
  };
  const startTimer = (): void => {
    timer.current = setTimeout(logOutAndNotify, SESSION_INACTIVITY_TIMEOUT);
  };
  const restartTimer = (): void => {
    stopTimer();
    startTimer();
  };
  const addEventListeners = (): void => {
    eventsToListenTo.forEach(event => {
      document.addEventListener(event, restartTimer);
    });
  };
  const removeEventListeners = (): void => {
    eventsToListenTo.forEach(event => {
      document.removeEventListener(event, restartTimer);
    });
  };
  useEffect(() => {
    if (isAuthenticated) {
      // need to startTimer or we don't logout if they don't move/click after login
      startTimer();
      addEventListeners();
    } else {
      // need to stopTimer or we will show notification after logout
      stopTimer();
      removeEventListeners();
    }
    return removeEventListeners;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return null;
};
