export enum ApiRoute {
  ANALYTICS = "/action",
  AVAILABLE_AGENCIES = "/available-agencies",
  USERS = "/users",
  AGENCY_RULES = "/agencies"
}

export const fetchOptions: RequestInit = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
};
