import { makeStyles, Theme } from "@material-ui/core";

export const useLayoutStyles = makeStyles(
  (theme: Theme) => ({
    stepContent: {
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      padding: theme.spacing(0, 8)
    }
  }),
  { name: "Layout" }
);
