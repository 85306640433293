import { Theme, makeStyles } from "@material-ui/core";

export const signInStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    width: theme.spacing(52),
    margin: "auto"
  },
  card: {
    minWidth: theme.spacing(87.5), // 700px
    padding: theme.spacing(3)
  },
  image: { height: "auto", width: "220px", marginBottom: theme.spacing(2.5) },
  authForm: {
    margin: "auto",
    width: "370px"
  },
  disclaimer: {
    marginTop: theme.spacing(4)
  }
}));
