/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
  AuthProvider,
  AuthEventCallback,
  AuthEventType,
  AuthEventChangeDisplayPayload,
  AuthEventUiInteractionPayload,
  REDIRECT_SIGN_IN_PATHNAME,
  NotificationsContainer,
  useSettings,
  PieAuthConfiguration
} from "@pie/components";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  analyticsAuthActions,
  AnalyticsAuthName
} from "../../store/analytics/submaps/auth";
import { authFormStatusTypeToActionName } from "./utils";
import App from "../App/App";
import { ActivityMonitor } from "../ActivityMonitor/ActivityMonitor";
import { useMount } from "@pie/utils";
import { PieCrustSettings } from "../../constants";
import { CustomCognitoAuthStorage } from "../../utils/customCognitoStorage";
import { CanaryContextProvider } from "../CanaryContextProvider";
import { AnalyticsInternalProperty, AppName } from "../../externals";
import { ICookieStorageData } from "amazon-cognito-identity-js";

export const initLocation = "initLocation";

const getCookieStorageConfig = (): ICookieStorageData => ({
  domain: window.location.hostname,
  secure: window.location.protocol.includes("https"),
  // path: "/",
  expires: 365,
  sameSite: "strict"
});

const generateAuthConfigWithCookieStorage = (
  authConfig: PieAuthConfiguration,
  useCustomAuth: boolean
) => {
  if (!authConfig) {
    return undefined;
  }

  return {
    ...authConfig,
    storage: useCustomAuth ? CustomCognitoAuthStorage : undefined,
    cookieStorage: useCustomAuth ? undefined : getCookieStorageConfig()
  };
};

export const AppBootstrap: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSettings<PieCrustSettings>();
  const {
    userPoolMapping,
    authConfiguration,
    useCookieAuth,
    useCustomAuth
  } = settings;

  useMount(() => {
    if (!window.location.pathname.includes(REDIRECT_SIGN_IN_PATHNAME)) {
      sessionStorage.setItem(initLocation, JSON.stringify(location));
    }
    if (process.env.REACT_APP_VERCEL_ENV !== "production") {
      console.log(
        process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,
        process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA
      );
    }
  });

  // Mouseflow tracking url changes
  // https://help.mouseflow.com/en/articles/4310818-tracking-url-changes-with-react
  React.useEffect(
    () =>
      history.listen(location => {
        (window as any)._mfq.push(["newPageView", location.pathname]);
        // custom Google Tag Manager event for page views
        window?.dataLayer?.push({
          event: "pageview"
        });
      }),
    [history]
  );
  React.useEffect(() => {
    // We also want the initial load to send a page view event to GTM
    window?.dataLayer?.push({
      event: "pageview"
    });
  }, []);

  const authEventCallback: AuthEventCallback = ({ type, payload }) => {
    switch (type) {
      case AuthEventType.LOGIN_SUCCESSFUL: {
        dispatch(
          analyticsAuthActions.authenticated({
            actionName: AnalyticsAuthName.SIGN_IN_SUCCESS,
            properties: {
              ...payload,
              [AnalyticsInternalProperty.SOURCE]: AppName
            },
            analyticsActions: ["TrackEvent", "UpdateProfile"]
          })
        );
        break;
      }
      case AuthEventType.LOGIN_FAILED: {
        dispatch(
          analyticsAuthActions.unauthenticated({
            actionName: AnalyticsAuthName.SIGN_IN_FAILED,
            properties: {
              ...payload,
              [AnalyticsInternalProperty.SOURCE]: AppName
            }
          })
        );
        break;
      }
      case AuthEventType.CHANGE_AUTH_FORM_DISPLAY: {
        const actionName = authFormStatusTypeToActionName(
          (payload as AuthEventChangeDisplayPayload).eventName
        );
        actionName &&
          dispatch(
            analyticsAuthActions.unauthenticated({
              actionName,
              type: "View",
              properties: {
                [AnalyticsInternalProperty.SOURCE]: AppName
              }
            })
          );
        break;
      }
      case AuthEventType.UI_INTERACTION: {
        const {
          eventName,
          properties
        } = payload as AuthEventUiInteractionPayload;
        dispatch(
          analyticsAuthActions.unauthenticated({
            actionName: eventName,
            properties: {
              [AnalyticsInternalProperty.SOURCE]: AppName,
              ...properties
            }
          })
        );
        break;
      }
    }
  };

  return (
    <AuthProvider
      getLocation={useLocation}
      userPoolMapping={userPoolMapping || {}}
      authConfiguration={
        useCookieAuth || useCustomAuth
          ? (generateAuthConfigWithCookieStorage(
              authConfiguration,
              useCustomAuth
            ) as PieAuthConfiguration)
          : authConfiguration
      }
      authEventCallback={authEventCallback}
    >
      <CanaryContextProvider>
        <App />
        <ActivityMonitor />
        <NotificationsContainer />
      </CanaryContextProvider>
    </AuthProvider>
  );
};
