import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { getAvailableAgencies } from "../../api/availableAgencies";
import { selectAuthUser, selectAuthUserIdToken } from "../../externals";
import { availableAgenciesActions } from "../../store/availableAgencies/availableAgencies";
import { notificationService } from "@pie/components";

export function* getAvailableAgenciesSaga(): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    const res = yield call(getAvailableAgencies, idToken);
    const availableAgencies = yield res.json();
    const { partner, partnerName } = yield select(selectAuthUser);
    yield put(
      availableAgenciesActions.getAvailableAgenciesSuccessful({
        authUserAgency: {
          id: partner,
          internalAgencyName: partnerName
        },
        availableAgencies
      })
    );
  } catch (e) {
    notificationService.addNotification({
      notificationType: "error",
      message: "Unable to retrieve Agency list. Try refreshing the page"
    });
  }
}
