import { makeStyles, Theme } from "@material-ui/core";
import { sideBarWidth, topBarZIndex } from "../../externals";

export const useSidebarStyles = makeStyles(
  (theme: Theme) => ({
    sideBar: {
      background: theme.palette.common.white,
      height: "100vh",
      width: sideBarWidth,
      flexGrow: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      zIndex: topBarZIndex + 1
    },
    upperWrapper: {
      display: "flex",
      flexDirection: "column"
    },
    logo: {
      padding: theme.spacing(3.75, 2.5),
      height: "auto",
      margin: "auto",
      width: theme.spacing(22.5) // 180px
    },
    email: {
      padding: theme.spacing(0, 2.5, 2.5)
    },
    buttonStyle: {
      width: "100%"
    },
    buttonMargin: {
      margin: theme.spacing(0, 2.5, 3.75) // "0 20px 30px"
    }
  }),
  { name: "SideBar" }
);
