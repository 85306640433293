import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Link, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import { useSettings } from "@pie/components";
import { useSidebarNavOptionStyles } from "./styles";
import {
  analyticsAction,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  FilterableNavOption,
  PieCrustSettings
} from "../../../externals";
import { useCanaryFlags } from "../../CanaryContextProvider";

export const SidebarNavOption: React.FC<FilterableNavOption> = ({
  IconComponent,
  name,
  label,
  path,
  testId
}) => {
  const dispatch = useDispatch();
  const classes = useSidebarNavOptionStyles();
  const canaryFlags = useCanaryFlags();
  const settings = useSettings<PieCrustSettings>();
  const migrated =
    Array.isArray(settings?.features?.migratedUis) &&
    settings.features.migratedUis.includes(name);
  const isCanaryRouted = canaryFlags?.[name]?.sidebar;

  const handleNavClick = (): void => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.NAV,
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName,
          [AnalyticsInternalProperty.NAV_ITEM]: label,
          path
        },
        type: "Click",
        options: ["trackRoute"]
      })
    );
  };

  const isActive = window.location.pathname.startsWith(path);

  return (
    <Grid item classes={{ root: classes.item }} key={name}>
      <Typography variant="body1">
        {migrated || isCanaryRouted ? (
          <Link
            className={isActive ? classes.migrated_activeLink : classes.link}
            data-testid={testId}
            href={path}
            title={path}
          >
            <IconComponent className={classes.icon} />
            {label}
          </Link>
        ) : (
          <NavLink
            activeClassName={classes.activeLink}
            className={classes.link}
            data-testid={testId}
            to={path}
            title={path}
            onClick={handleNavClick}
          >
            <IconComponent className={classes.icon} />
            {label}
          </NavLink>
        )}
      </Typography>
    </Grid>
  );
};
