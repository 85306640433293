import React, { FC } from "react";
import { PredicateFunction } from "../../../externals";
import { useAuth } from "@pie/components";
import { Route, RouteProps } from "react-router";
import { NotAuthorized } from "../../NotAuthorized/NotAuthorized";

export interface GuardRouteProps extends RouteProps {
  allowRoute?: PredicateFunction[];
  disableRoute?: PredicateFunction[];
  componentWhenNotAuthorized?: () => JSX.Element;
}
export const GuardRoute: FC<GuardRouteProps> = ({
  allowRoute,
  disableRoute,
  componentWhenNotAuthorized: ComponentWhenNotAuthorized = () => (
    <NotAuthorized />
  ),
  ...rest
}) => {
  const { user } = useAuth();

  const isGuarded = (routes: PredicateFunction[] | undefined) =>
    routes?.some(route => user && route(user));

  if (isGuarded(allowRoute) && !isGuarded(disableRoute)) {
    return <Route {...rest} />;
  } else if (!isGuarded(allowRoute)) {
    return <Route {...rest} render={ComponentWhenNotAuthorized} />;
  }

  return null;
};
