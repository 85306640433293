import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSettings } from "@pie/components";
import { defaultStrategy, ppsStrategy } from "./redirectStrategy";
import { AuthRoutes, PieCrustSettings } from "../../constants";
import { PolicySubmissionRedirectProps } from "./types";
import { selectAuthUserIdToken } from "../../externals";

export const PolicySubmissionRedirect: React.FC<PolicySubmissionRedirectProps> = ({
  user,
  search
}) => {
  const settings = useSettings<PieCrustSettings>();
  const enablePolicy = Boolean(settings?.features?.Policy);

  const [link, setLink] = useState<string>("");

  const idToken = useSelector(selectAuthUserIdToken);

  useEffect(() => {
    const determineAndSetRedirectLink = async () =>
      /*
        Choose the PPSStrategy if the PolicyUI flag is enabled
       */
      enablePolicy
        ? await ppsStrategy(user, search, settings, idToken ?? "")
        : await defaultStrategy(user, search);

    determineAndSetRedirectLink()
      .then(link => {
        setLink(link);
      })
      .catch(e => {
        console.error("woops!", e);
        // likely a 401 error for an unauthorized user, send em' home
        setLink(AuthRoutes.HOME);
      });
  }, [enablePolicy, idToken, search, settings, user]);

  return <>{link && <Redirect to={link}>Redirected</Redirect>}</>;
};
