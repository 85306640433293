import { MicroUILoader, useSettings } from "@pie/components";
import React, { FC } from "react";
import { AuthRoutes, PieCrustSettings } from "../../../constants";
import { redirectWithoutSPARouting } from "../../../utils/routingUtils";
import { useCanaryFlags } from "../../CanaryContextProvider";

interface MicroUIWrapperProps {
  route: AuthRoutes;
  appName: string;
  store: unknown;
}

export const MicroUIWrapper: FC<MicroUIWrapperProps> = ({
  appName,
  store,
  route
}) => {
  const settings = useSettings<PieCrustSettings>();
  const canaryFlags = useCanaryFlags();
  const migratedUis = (settings?.features?.migratedUis || []) as string[];
  const cleanedRoute = route.replace("/", "");
  const isCanaryMigrated = canaryFlags?.[cleanedRoute]?.["micro-ui-wrapper"];

  const isMigratedRoute = migratedUis.find(ui => route.startsWith(`/${ui}`));
  if (
    // Don't render the MicroUI if the migratedUis is set
    (isMigratedRoute || isCanaryMigrated) &&
    // Use the redirect to prevent an infinite loop if vercel doesn't render the route
    !window.location.href.includes("redirect=1")
  ) {
    redirectWithoutSPARouting();
    return null;
  }
  return <MicroUILoader appName={appName} store={store} />;
};
