import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { Notification, PieCrustStateKey } from "../..";

type AddNotificationPayload = Omit<Notification, "id">;
export type AddNotificationAction = PayloadAction<AddNotificationPayload>;
export const addNotificationAction = createAction<AddNotificationPayload>(
  `${PieCrustStateKey.NOTIFICATIONS}/addNotification`
);

export type RemoveNotificationAction = PayloadAction<string>;
export const removeNotificationAction = createAction<string>(
  `${PieCrustStateKey.NOTIFICATIONS}/removeNotification`
);

export const notificationsActions = {
  addNotification: addNotificationAction,
  removeNotification: removeNotificationAction
};
