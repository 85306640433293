import { GraphQLClient } from "@pie/components";
import { PieCrustSettings } from "../constants";

export const PPSClient = (
  config: PieCrustSettings,
  idToken: string
): GraphQLClient =>
  new GraphQLClient({
    headers: {
      authorization: idToken
    },
    uri: config?.api?.appSync?.url
  });
