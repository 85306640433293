import { ApiRoute, fetchOptions } from "./constants";

export const getAgencyRules = (
  idToken: string,
  agencyId: string
): Promise<Response> =>
  fetch(`/api/v1${ApiRoute.AGENCY_RULES}/${agencyId}/rules`, {
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    },
    method: "GET"
  });
