import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PieCrustStateKey } from "../../externals";
import { AgencyRulesState } from "../../externals/store/agencyRules/types";

export type GetAgencyRulesSuccessfulAction = PayloadAction<
  GetAgencyRulesSuccessfulPayload
>;

export interface GetAgencyRulesSuccessfulPayload {
  canUseApiKeys: boolean;
}

export const getAgencyRules = createAction(
  `${PieCrustStateKey.AGENCY_RULES}/getAgencyRules`
);

const initialState: AgencyRulesState = {
  canUseApiKeys: false
};

const agencyRules = createSlice({
  name: PieCrustStateKey.AGENCY_RULES,
  initialState,
  reducers: {
    getAgencyRulesSuccessful: (
      state: AgencyRulesState,
      action: GetAgencyRulesSuccessfulAction
    ): AgencyRulesState => {
      const { canUseApiKeys } = action.payload;
      return {
        ...state,
        canUseApiKeys
      };
    }
  }
});

export const agencyRulesActions = {
  ...agencyRules.actions,
  getAgencyRules: getAgencyRules
};

export const agencyRulesActionTypes = {
  getAgencyRules: agencyRulesActions.getAgencyRules.type,
  getAgencyRulesSuccessful: agencyRulesActions.getAgencyRulesSuccessful.type
};

export const agencyRulesReducer = agencyRules.reducer;
