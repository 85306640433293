import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Link } from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";
import {
  SelectWithSublabel,
  useAuth,
  useSettings,
  SelectOption
} from "@pie/components";
import AnnounceKit from "announcekit-react";

import { useTopBarStyles } from "./styles";
import {
  analyticsAction,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  selectAuthUser,
  selectSelectedAgencyId
} from "../../externals";

import { TestIds } from "../../constants";
import { useMount } from "@pie/utils";
import { availableAgenciesActions } from "../../store/availableAgencies/availableAgencies";
import { selectSelectedAgencyAsOption } from "./selectors";

export interface ReleaseNotesSettings {
  active: boolean;
  widgetURL: string;
}
export interface AppSettings {
  features?: {
    ReleaseNotes?: ReleaseNotesSettings;
    SubmitOnBehalfOf?: boolean;
  };
}
export const TopBar: React.FC = () => {
  const { logout } = useAuth();
  const { topBar, link, topBarExitToApp } = useTopBarStyles();
  const dispatch = useDispatch();

  const {
    email,
    firstName,
    lastName,
    role,
    partner,
    partnerName
  } = useSelector(selectAuthUser);

  const selectedAgencyId = useSelector(selectSelectedAgencyId);
  const { features } = useSettings<AppSettings>();

  const agenciesOptions: SelectOption[] = useSelector(
    selectSelectedAgencyAsOption
  );

  const releaseNotes = features && features.ReleaseNotes;

  // needs to stay in place in order to create the redux store until AAT-875
  // is addressed
  useMount(() => {
    dispatch(availableAgenciesActions.getAvailableAgencies());
  });

  const handleLogout = (): void => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.SIGN_OUT,
        type: "Click",
        options: ["trackRoute"],
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName
        }
      })
    );
    logout();
  };

  return (
    <Grid
      container
      data-testid={TestIds.TOP_BAR}
      direction="row"
      justify="space-between"
      alignItems="center"
      className={topBar}
    >
      <Grid data-testId={TestIds.AGENCY_SELECT} item xs="auto">
        <SelectWithSublabel
          placeholder={!selectedAgencyId ? "Select an Agency" : undefined}
          options={agenciesOptions}
          sublabel={`${firstName || ""} ${lastName || ""}`}
          value={selectedAgencyId}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row">
          {email && releaseNotes && releaseNotes.active && (
            <Typography
              component="div"
              color="textPrimary"
              className={link}
              data-testId={TestIds.FEATURE_UPDATES}
            >
              <AnnounceKit
                widget={releaseNotes.widgetURL}
                user={{
                  id: email,
                  firstName,
                  lastName,
                  role,
                  partnerName
                }}
                data={{ role, partner, email }}
              >
                Feature Updates
              </AnnounceKit>
            </Typography>
          )}
          <Typography
            component="div"
            data-testId={TestIds.LOGOUT}
            variant="body1"
          >
            <Link className={link} onClick={handleLogout} color="inherit">
              <ExitToAppIcon className={topBarExitToApp} />
              Sign Out
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
