import { createSelector } from "@reduxjs/toolkit";
import { AuthUserState } from "./types";
import { PieCrustApplicationState, PieCrustStateKey } from "../../types";

const selectAuthUserState = (state: PieCrustApplicationState): AuthUserState =>
  state[PieCrustStateKey.AUTH_USER];

export const selectAuthUser = createSelector(
  selectAuthUserState,
  authUser => authUser
);

export const selectAuthUserRole = createSelector(
  selectAuthUserState,
  ({ role }) => role
);

export const selectAuthUserIdToken = createSelector(
  selectAuthUserState,
  ({ idToken }) => idToken
);

export const selectAuthUserPartnerId = createSelector(
  selectAuthUserState,
  ({ partner }) => partner
);

export const selectAuthUserPartnerName = createSelector(
  ({ authUser }: PieCrustApplicationState): string | undefined =>
    authUser.partnerName,
  partnerName => partnerName
);

export const selectAuthUserUsername = createSelector(
  ({ authUser }: PieCrustApplicationState): string => authUser.username,
  username => username
);

export const selectAuthUserLastName = createSelector(
  selectAuthUserState,
  ({ lastName }) => lastName
);

export const selectAuthUserFirstName = createSelector(
  selectAuthUserState,
  ({ firstName }) => firstName
);
