import { analyticsActions, AnalyticsUser } from "@pie/components";
import { createSlice } from "@reduxjs/toolkit";
import { PieCrustStateKey } from "../../externals";

const initialState = {} as AnalyticsUser;

const analyticsUserSlice = createSlice({
  name: PieCrustStateKey.ANALYTICS_USER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      analyticsActions.locationChange,
      (_state, action) => action.payload.analyticsUser
    );
  }
});

export default analyticsUserSlice.reducer;
