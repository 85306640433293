import { UserRole, notificationService } from "@pie/components";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { getUsersByAgency } from "../../api/users";
import { Agent, selectAuthUser } from "../../externals";
import {
  availableAgenciesActions,
  SetSelectedAgencyAction
} from "../../store/availableAgencies/availableAgencies";
interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export function* getUsersSaga(action: SetSelectedAgencyAction): SagaIterator {
  const { role, idToken } = yield select(selectAuthUser);
  if (role === UserRole.PieAdmin) {
    try {
      const id = action.payload.isProgram
        ? action.payload.parentAgencyId || ""
        : action.payload.id;
      const res = yield call(getUsersByAgency, idToken, id);
      if (res.ok) {
        const body = yield res.json();
        const agents: Agent[] = body.map(
          ({ id, firstName, lastName }: User) => ({
            id,
            name: `${firstName || ""} ${lastName || ""}`
          })
        );
        if (!agents.length) {
          throw new Error(
            "There are no agents available for the selected agency."
          );
        }
        yield put(
          availableAgenciesActions.setAgencyUsers({
            agencyId: action.payload.id,
            agents
          })
        );
      } else {
        throw res;
      }
    } catch (e) {
      notificationService.addNotification({
        notificationType: "error",
        message:
          e.message || "Unable to retrieve agent list for selected agency."
      });
    }
  }
}
