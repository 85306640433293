import { createAction, ActionCreatorWithPayload } from "@reduxjs/toolkit";

export type AnalyticsGenericActionType = "analytics/action";
export const analyticsGenericActionType: AnalyticsGenericActionType =
  "analytics/action";

export enum AnalyticsClickName {
  GET_A_QUOTE = "Get a Quote",
  NAV = "Nav",
  PIE_LOGO = "Pie Logo",
  SIGN_OUT = "Sign Out",
  TECH_ERROR_PAGE_RETURN = "Technical Errors Page Return",
  TECH_ERROR_PAGE = "Technical Errors Page",
  NOT_FOUND_PAGE = "Not Found Page",
  ACCESS_RESTRICTED_PAGE = "Access Restricted Page"
}

export const analyticsAction: AnalyticsActionCreator = createAction<
  AnalyticsActionPayload,
  AnalyticsGenericActionType
>(analyticsGenericActionType);

export type AnalyticsActionType = "TrackEvent" | "UpdateProfile";
export interface AnalyticsEvent {
  analyticsActions: AnalyticsActionType[];
  actionName: string;
  actionTime?: Date;
  properties?: Record<string, unknown>;
  user?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    userCreateTime?: Date;
  };
  ignoreActionTime?: boolean;
  ignoreAlias?: boolean;
  distinctId?: string;
}
export interface AnalyticsCustomActionPayload
  extends Pick<AnalyticsEvent, "actionName" | "properties"> {
  analyticsActions?: AnalyticsActionType[];
}
export enum AnalyticsEventBehavior {
  VIEW = "View",
  SUBMIT = "Submit",
  CLICK = "Click",
  BACK_CLICK = "Backclick",
  ERROR = "Error"
}
export type AnalyticsBehaviorType =
  | "Click"
  | "View"
  | "Backclick"
  | "Submit"
  | "Error";
export type AnalyticsOptionsType = "trackRoute";
export interface AnalyticsActionPayload extends AnalyticsCustomActionPayload {
  options?: AnalyticsOptionsType[];
  type?: AnalyticsBehaviorType;
}
export type AnalyticsActionCreator = ActionCreatorWithPayload<
  AnalyticsActionPayload,
  AnalyticsGenericActionType
>;

export interface OptionalAnalyticsEvent
  extends Omit<AnalyticsEvent, "analyticsActions" | "distinctId" | "user"> {
  analyticsActions?: AnalyticsActionType[];
}

export enum AnalyticsInternalProperty {
  VALIDATION_ERRORS = "validationErrors",
  NAV_ITEM = "navItem",
  SOURCE = "application_name"
}

export enum AnalyticsActionName {
  LAND_WITH_QUERYPARAMS = "Landed with QueryParameters"
}

export const AppName = "partner portal";
