import { ApiRoute, fetchOptions } from "./constants";

export const getAvailableAgencies = (idToken: string): Promise<Response> =>
  fetch(`/api/v1${ApiRoute.AVAILABLE_AGENCIES}`, {
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    },
    method: "GET"
  });
