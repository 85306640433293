export enum TestIds {
  AGENCY_SELECT = "pcAgencySelect",
  FEATURE_UPDATES = "pcFeatureUpdates",
  LOGOUT = "pcLogout",
  NAV_API_KEYS = "pcNavApiKeys",
  NAV_APPETITE = "pcNavAppetite",
  NAV_BILLING = "pcNavBilling",
  NAV_CONTACT = "pcNavContact",
  NAV_DASHBOARD = "pcNavDashboard",
  NAV_PAY_PLAN = "pcNavPayPlan",
  NAV_PROVISIONING = "pcNavProvisioning",
  NAV_ROSTER = "pcNavRoster",
  NAV_USER_MANAGEMENT = "pcNavUserManagement",
  QUOTE_BUTTON = "pcQuoteButton",
  ROUTED_LOGO = "pcRoutedLogo",
  SIDEBAR = "pcSidebar",
  TOP_BAR = "pcTopBar"
}
