import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import {
  selectAuthUserIdToken,
  selectAuthUserPartnerId
} from "../../externals";
import { notificationService } from "@pie/components";
import { getAgencyRules } from "../../api/agencyRules";
import { agencyRulesActions } from "../../store/agencyRules/agencyRules";

export function* getAgencyRulesSaga(): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    const partnerId = yield select(selectAuthUserPartnerId);
    const res = yield call(getAgencyRules, idToken, partnerId);
    const agencyRules = yield res.json();
    yield put(
      agencyRulesActions.getAgencyRulesSuccessful({
        canUseApiKeys: agencyRules["canUseApiKeys"]
      })
    );
  } catch (e) {
    notificationService.addNotification({
      notificationType: "error",
      message: "Unable to retrieve Agency Rules. Try refreshing the page"
    });
  }
}
