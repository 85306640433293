/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  configureStore,
  getDefaultMiddleware,
  EnhancedStore,
  Reducer,
  Unsubscribe
} from "@reduxjs/toolkit";
import { createBrowserHistory, History } from "history";
import createSagaMiddleware, { Task, Saga } from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { throttle, noop } from "lodash";
import offlineWeb from "@redux-beacon/offline-web";

import { saveState, loadState } from "./sessionStorage";
import rootSaga from "./root/rootSaga";
import { createAnalyticsMiddleware, AnyEventMap } from "./analytics/middleware";
import { startConnectionListeners } from "./connection";
import { createRootReducer } from "./root/rootReducer";
import { PieCrustApplicationState } from "../externals";

export interface InjectionFunctions {
  injectReducer(key: string, reducer: Reducer): Promise<string>;
  injectSaga(key: string, saga: Saga): Promise<string>;
  injectAnalyticsEvents(newEvents: AnyEventMap): Promise<string>;
  subscribe(listener: () => void): Unsubscribe;
  getState<S = PieCrustApplicationState>(): S;
}

export type AppStoreType = EnhancedStore &
  InjectionFunctions & {
    asyncReducers: { [key: string]: Reducer };
    asyncSagas: Map<string, Task>;
  };

const sagaMiddleware = createSagaMiddleware();

const offlineStorage = offlineWeb(
  (state: PieCrustApplicationState) => state.connection
);
const analyticsMiddleware = createAnalyticsMiddleware(offlineStorage);

const createReduxStore = (history: History) => {
  // // Generate initial reducer with no AsyncReducers
  const reducer = createRootReducer(history, {});

  const store = configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware({ thunk: false }),
      sagaMiddleware,
      routerMiddleware(history),
      analyticsMiddleware.middleware
    ],
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: loadState()
  }) as AppStoreType;
  startConnectionListeners(store);

  // Setup store for dynamic sub app events
  store.injectAnalyticsEvents = (newEvents: AnyEventMap): Promise<string> => {
    try {
      analyticsMiddleware.addDynamicEvents(newEvents);
      return Promise.resolve("injectAnalyticsEvents success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  // Setup store for loading external reducers
  store.asyncReducers = {};
  store.injectReducer = (key: string, asyncReducer: any): Promise<string> => {
    try {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(createRootReducer(history, store.asyncReducers));
      return Promise.resolve("injectReducer success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  // Setup store for loading external sagas
  store.asyncSagas = new Map();
  store.injectSaga = (key: string, asyncSaga: any): Promise<string> => {
    try {
      const existingTask = store.asyncSagas.get(key);
      // Don't replace unless this in dev/hot.module
      if (!module.hot && existingTask)
        return Promise.resolve("injectSaga success HMR");
      if (module.hot && existingTask && existingTask.isRunning()) {
        existingTask.cancel();
        while (!existingTask.isCancelled()) {
          noop();
        }
      }

      // Start the saga and save task so we can cancel if needed in the future
      const task = sagaMiddleware.run(asyncSaga);
      store.asyncSagas.set(key, task);
      return Promise.resolve("injectSaga success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  let sagaTask = sagaMiddleware.run(rootSaga);
  // Write the changes to the state to sessionStorage
  store.subscribe(
    throttle(() => {
      if (process.env.NODE_ENV !== "production") {
        const state = store.getState();
        saveState(state);
      }
    }, 100)
  );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./root/rootReducer", async () => {
      const newReducers = (
        await import("./root/rootReducer")
      ).createRootReducer(history, store.asyncReducers);
      store.replaceReducer(newReducers);
    });
    module.hot.accept("./root/rootSaga", async () => {
      const newSagas = (await import("./root/rootSaga")).default;
      sagaTask.cancel();
      while (!sagaTask.isCancelled()) {
        noop();
      }
      sagaTask = sagaMiddleware.run(newSagas);
    });
  }

  return store;
};

export const history = createBrowserHistory();

export const store = createReduxStore(history);
