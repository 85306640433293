import React from "react";
import { AuthForm, Card } from "@pie/components";
import { Grid, Link } from "@material-ui/core";
import queryString from "query-string";
import { signInStyles } from "./styles";
import { useHistory, useLocation } from "react-router";
import { Icon as IconAtom, LogoOrangeNameRight } from "@pie/icon-atom";

export const SignIn: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  if (location.search.includes("redirect=1")) {
    const search = queryString.parse(location.search);
    delete search.redirect;
    const newParams = queryString.stringify(search);

    let newLocation = location.pathname;
    if (newParams.length !== 0) {
      newLocation += `?${newParams}`;
    }
    history.push(newLocation);
  }

  const { authForm, card, disclaimer, image, root } = signInStyles();
  // TODO: AuthForm should take a function to dispatch an analytics call to
  // update role.
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={root}
    >
      <Grid item>
        <Card className={card} displayStyle="tertiary">
          <Grid container justifyContent="center">
            <Grid item>
              <IconAtom className={image} source={LogoOrangeNameRight} />
            </Grid>
          </Grid>
          <div className={authForm}>
            <AuthForm />
          </div>
          <div className={disclaimer}>
            Policies are sold and administered by Pie Insurance Services, Inc.,
            a licensed insurance producer (licenses available at{" "}
            <Link
              href="https://pieinsurance.com/legal/"
              rel="noopener noreferrer"
              target="_blank"
              underline="always"
            >
              pieinsurance.com/legal/
            </Link>
            ) and underwritten by affiliated and unaffiliated insurers.
            Information provided by an applicant or insured is subject to our
            Privacy Policy (
            <Link
              href="https://pieinsurance.com/privacy/"
              rel="noopener noreferrer"
              target="_blank"
              underline="always"
            >
              pieinsurance.com/privacy/
            </Link>
            ). Not available in all states and situations. Coverage subject to
            policy terms and conditions.
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
