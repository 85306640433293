import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PieCrustStateKey } from "../../externals";
import { clearAuthUser, AuthUserState } from "../../externals/store/authUser";

const initialState = {} as AuthUserState;

export type SetAuthUserAction = PayloadAction<AuthUserState>;

const authUser = createSlice({
  name: PieCrustStateKey.AUTH_USER,
  initialState,
  reducers: {
    setAuthUser: (state, action: SetAuthUserAction): AuthUserState =>
      action.payload
  },
  extraReducers: builder => {
    builder.addCase(clearAuthUser, (): AuthUserState => initialState);
  }
});

export const authUserActions = {
  setAuthUser: authUser.actions.setAuthUser,
  clearAuthUser
};
export const authUserActionTypes = {
  setAuthUser: authUserActions.setAuthUser.type,
  clearAuthUser: authUserActions.clearAuthUser.type
};

export default authUser.reducer;
