import { Loader, useAuth } from "@pie/components";
import React, { useEffect } from "react";
import { Redirect } from "react-router";

export const SignOut: React.FC = () => {
  const auth = useAuth();
  const { logout, isAuthenticated, user, loading } = auth;
  useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
  }, [loading, isAuthenticated, logout]);
  // Need to check for user as well because there is a small window where user if logged in, but isAuthenticated is still false
  return !isAuthenticated && !loading && user == null ? (
    <Redirect to="/sign-in" />
  ) : (
    <Loader />
  );
};
