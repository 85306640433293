import { AuthUser, useAuth, useSettings } from "@pie/components";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { CanaryFlag, fetchFlags, logError } from "./utils";
import { PieCrustSettings } from "../../constants";

type CanaryProviderContext = Record<string, CanaryFlag> | null;

const CanaryProvider = createContext<CanaryProviderContext>(null);

export const useCanaryFlags = (): CanaryProviderContext =>
  useContext(CanaryProvider);

export const CanaryContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const { user } = useAuth();
  const settings = useSettings<PieCrustSettings>();
  const ldClientKey =
    settings.launchDarklyConfiguration?.canaryRouterClientId || null;

  const [contextValue, setContextValue] = useState<CanaryProviderContext>(null);
  // Use this to prevent multiple api calls
  const isFetchingFlags = useRef(false);

  // Fetch the launchdarkly flags that start with CANARY_KEY
  const getAllFlags = useCallback(
    async (user: AuthUser) => {
      if (ldClientKey == null) {
        logError("Reason: Invalid LD Client flag");
        return;
      }
      isFetchingFlags.current = true;
      const flags = await fetchFlags(ldClientKey, user);
      // If fetch flags does not return a result end function early so we can try again
      if (flags == null) return;

      setContextValue(flags);
    },
    [setContextValue, ldClientKey]
  );

  useEffect(() => {
    // If no user or flags is set do not make the flags api call
    if (!user) return;
    if (contextValue) return;
    if (isFetchingFlags.current) return;
    getAllFlags(user);
  }, [user, contextValue, getAllFlags]);

  return (
    <CanaryProvider.Provider value={contextValue}>
      {children}
    </CanaryProvider.Provider>
  );
};
