import { makeStyles, Theme } from "@material-ui/core";
import { topBarHeight, topBarZIndex } from "../../externals/styles/constants";

export const useTopBarStyles = makeStyles(
  (theme: Theme) => ({
    topBar: {
      flexBasis: topBarHeight,
      padding: theme.spacing(0, 8),
      flexGrow: 0,
      right: 0,
      top: 0,
      width: "100%",
      background: theme.palette.grey[100],
      zIndex: topBarZIndex,
      marginBottom: theme.spacing(2.5)
    },
    topBarExitToApp: {
      paddingRight: theme.spacing(0.5)
    },
    link: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      "& a": {
        color: theme.palette.text.primary,
        textDecoration: "none"
      }
    }
  }),
  { name: "TopBar" }
);
