const COGNITO_COOKIE = "CognitoIdentityServiceProvider";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    name = name.trim();

    if (name.startsWith(COGNITO_COOKIE)) {
      if (
        name === `${COGNITO_COOKIE}.idToken` ||
        name === `${COGNITO_COOKIE}.accessToken` ||
        name === `${COGNITO_COOKIE}.refreshToken`
      ) {
        continue;
      }

      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }
}
