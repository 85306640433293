import * as React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/pro-duotone-svg-icons";

export const UserManagementIcon: React.FC<Partial<
  FontAwesomeIconProps
>> = props => <FontAwesomeIcon icon={faUserFriends} {...props} />;
