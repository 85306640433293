import { createSlice, createSelector } from "@reduxjs/toolkit";
import { generateUniqueId } from "@pie/utils";
import {
  NotificationsState,
  PieCrustApplicationState,
  PieCrustStateKey
} from "../../externals";
import {
  AddNotificationAction,
  addNotificationAction,
  notificationsActions,
  RemoveNotificationAction,
  removeNotificationAction
} from "../../externals/store/notifications/actions";

const initialState: NotificationsState = [];

const notificationSlice = createSlice({
  name: PieCrustStateKey.NOTIFICATIONS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        addNotificationAction,
        (
          state: NotificationsState,
          action: AddNotificationAction
        ): NotificationsState => [
          ...state,
          {
            id: generateUniqueId(),
            ...action.payload
          }
        ]
      )
      .addCase(
        removeNotificationAction,
        (
          state: NotificationsState,
          action: RemoveNotificationAction
        ): NotificationsState =>
          state.filter(notification => notification.id !== action.payload)
      );
  }
});

export const notificationActionTypes = {
  addNotification: notificationsActions.addNotification.toString(),
  removeNotification: notificationsActions.removeNotification.toString()
};

export const selectNotifications = createSelector(
  (state: PieCrustApplicationState) => state.notifications,
  notifications => notifications
);

export default notificationSlice.reducer;
