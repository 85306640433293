import { ApiRoute, fetchOptions } from "./constants";

export const getUsersByAgency = (
  idToken: string,
  id: string
): Promise<Response> =>
  fetch(`/internalapi/v1${ApiRoute.USERS}?partnerId=${id}`, {
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    },
    method: "GET"
  });
