import { FC } from "react";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { RouterState } from "connected-react-router";
import { SvgIconProps } from "@material-ui/core";
import { UserRole, AuthUser } from "@pie/components";

import { AuthUserState } from "../store/authUser";
import { AnalyticsUserState } from "../store/analytics";
import { AvailableAgenciesState } from "../store/availableAgencies";
import { AgencyRulesState } from "../store/agencyRules";

export type ConnectionState = boolean;
export interface Notification {
  id: string;
  message: string;
  type: "error" | "success";
}
export type NotificationsState = Notification[];

export enum PieCrustStateKey {
  ROUTER = "router",
  AUTH_USER = "authUser",
  CONNECTION = "connection",
  NOTIFICATIONS = "notifications",
  AVAILABLE_AGENCIES = "availableAgencies",
  ANALYTICS_USER = "analyticsUser",
  AGENCY_RULES = "agencyRules"
}

export interface PieCrustApplicationState {
  [PieCrustStateKey.ROUTER]: RouterState;
  [PieCrustStateKey.AUTH_USER]: AuthUserState;
  [PieCrustStateKey.CONNECTION]: ConnectionState;
  [PieCrustStateKey.NOTIFICATIONS]: NotificationsState;
  [PieCrustStateKey.AVAILABLE_AGENCIES]: AvailableAgenciesState;
  [PieCrustStateKey.AGENCY_RULES]: AgencyRulesState;
  [PieCrustStateKey.ANALYTICS_USER]: AnalyticsUserState;
}

export type PredicateFunction = (authUser: AuthUser) => boolean;

export type IconComponent =
  | ((props: SvgIconProps) => JSX.Element)
  | FC<Partial<FontAwesomeIconProps>>;

/*
  TODO:  Why is this exported?
  Investigate these externals to see if we really need to export these items.
*/
export interface FilterableNavOption {
  roles: UserRole[];
  IconComponent: IconComponent;
  name: string;
  label: string;
  path: string;
  predicates: PredicateFunction[];
  testId: string;
}
