import { makeStyles, Theme } from "@material-ui/core";

export const useSidebarNavOptionStyles = makeStyles(
  (theme: Theme) => ({
    item: {
      width: "100%"
    },
    link: {
      width: "100%",
      padding: theme.spacing(1.25),
      display: "flex",
      textDecoration: "none",
      borderLeft: `5px solid ${theme.palette.common.white}`,
      color: theme.palette.text.primary,
      boxSizing: "border-box",
      "&:hover": {
        textDecoration: "none",
        background: theme.palette.primary.light,
        borderLeft: `5px solid ${theme.palette.primary.main}`
      }
    },
    activeLink: {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1.25)
    },
    migrated_activeLink: {
      width: "100%",
      display: "flex",
      textDecoration: "none",
      borderLeft: `5px solid ${theme.palette.common.white}`,
      boxSizing: "border-box",
      "&:hover": {
        textDecoration: "none",
        background: theme.palette.primary.light,
        borderLeft: `5px solid ${theme.palette.primary.main}`
      },
      // ActiveLink Copy
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1.25)
    },
    icon: {
      padding: theme.spacing(0, 2),
      width: 22,
      height: 22
    }
  }),
  { name: "SideBar" }
);
