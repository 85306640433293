import { createSelector } from "@reduxjs/toolkit";
import { AvailableAgenciesState } from "./types";
import { PieCrustApplicationState, PieCrustStateKey } from "../../types";

const selectAvailableAgenciesState = (
  state: PieCrustApplicationState
): AvailableAgenciesState => state[PieCrustStateKey.AVAILABLE_AGENCIES];

export const selectAvailableAgenciesCollection = createSelector(
  selectAvailableAgenciesState,
  availableAgencies => availableAgencies.collection
);

export const selectSelectedAgencyId = createSelector(
  selectAvailableAgenciesState,
  availableAgencies => availableAgencies.selectedAgencyId
);
