import { makeStyles, Theme } from "@material-ui/core";

export const useAppStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: theme.palette.grey[100],
      minHeight: "100vh"
    }
  }),
  { name: "App" }
);
