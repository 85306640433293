import { Loader, useSettings } from "@pie/components";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { determineRoleHome } from "../../routes/routes";
import { initLocation } from "../AppBootstrap/AppBootstrap";
import { PieCrustSettings, PublicRoutes } from "../../constants";
import { selectAuthUser } from "../../externals";
import { useMount } from "@pie/utils";
import queryString from "query-string";
import { useLocation } from "react-router";

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const settings = useSettings<PieCrustSettings>();
  const isPolicyEnabled = Boolean(settings?.features?.Policy);

  const authUser = useSelector(selectAuthUser);
  const roleHome = determineRoleHome(authUser, isPolicyEnabled);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false);

  useMount(() => {
    const { redirectUrl } = queryString.parse(location.search);
    if (typeof redirectUrl === "string") {
      dispatch(push(redirectUrl));
      setIsMounted(true);
      return;
    }
    const storageInitLocation = sessionStorage.getItem(initLocation);
    sessionStorage.removeItem(initLocation);

    if (storageInitLocation) {
      const parsedLocation = JSON.parse(storageInitLocation) as Location;
      const matchesPublicRoute = Object.entries(PublicRoutes).find(
        ([key, pathname]) => pathname === parsedLocation.pathname
      );
      if (parsedLocation.pathname === "/" || matchesPublicRoute) {
        parsedLocation.pathname = roleHome;
      }
      dispatch(push(parsedLocation));
      setIsMounted(true);
      return;
    } else {
      dispatch(push(roleHome));
      setIsMounted(true);
      return;
    }
  });
  useEffect(() => {
    // this handles fallthrough if pushing to an unknown route. Could be a 404 if we had one.
    let timer: NodeJS.Timeout | null;
    if (isMounted) {
      timer = setTimeout(() => {
        setHasTimedOut(true);
      }, 5000);
    }
    if (isMounted && hasTimedOut) {
      dispatch(push(roleHome));
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [dispatch, isMounted, roleHome, hasTimedOut]);

  return <Loader />;
};
