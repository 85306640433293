import { Reducer, AnyAction, combineReducers } from "@reduxjs/toolkit";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import { PieCrustApplicationState, PieCrustStateKey } from "../../externals";
import authUser from "../authUser";
import connection from "../connection";
import notifications from "../notifications";
import analyticsUser from "../analytics";
import { availableAgenciesReducer } from "../availableAgencies/availableAgencies";
import { agencyRulesReducer } from "../agencyRules/agencyRules";

export const createRootReducer = (
  history: History,
  asyncReducers?: { [key: string]: Reducer }
): Reducer<PieCrustApplicationState> =>
  combineReducers<PieCrustApplicationState>({
    ...asyncReducers,
    [PieCrustStateKey.ROUTER]: connectRouter(history) as Reducer<
      RouterState<unknown>,
      AnyAction
    >,
    [PieCrustStateKey.AUTH_USER]: authUser,
    [PieCrustStateKey.CONNECTION]: connection,
    [PieCrustStateKey.NOTIFICATIONS]: notifications,
    [PieCrustStateKey.AVAILABLE_AGENCIES]: availableAgenciesReducer,
    [PieCrustStateKey.AGENCY_RULES]: agencyRulesReducer,
    [PieCrustStateKey.ANALYTICS_USER]: analyticsUser
  });
