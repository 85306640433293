import { all, takeLatest, takeLeading } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { availableAgenciesActionTypes } from "../availableAgencies/availableAgencies";
import { getAvailableAgenciesSaga } from "../../sagas/getAvailableAgenciesSaga/getAvailableAgenciesSaga";
import { getUsersSaga } from "../../sagas/getUsersSaga/getUsersSaga";
import { agencyRulesActionTypes } from "../agencyRules/agencyRules";
import { getAgencyRulesSaga } from "../../sagas/getAgencyRulesSaga/getAgencyRulesSaga";

export default function* rootSaga(): SagaIterator {
  yield all([
    takeLeading(
      availableAgenciesActionTypes.getAvailableAgencies,
      getAvailableAgenciesSaga
    ),
    takeLatest(availableAgenciesActionTypes.setSelectedAgencyId, getUsersSaga),
    takeLatest(agencyRulesActionTypes.getAgencyRules, getAgencyRulesSaga)
  ]);
}
