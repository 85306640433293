import React, { FC } from "react";
import { NotFound } from "../../NotFound/NotFound";

interface NewAppRouteProps {
  path: string;
}

export const NewAppRoute: FC<NewAppRouteProps> = () => {
  if (window.location.href.includes("redirect=1")) {
    return <NotFound />;
  }

  window.location.reload();
  return null;
};
