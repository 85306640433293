import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "@pie/utils";
import { AccessRestrictedPage, useSettings } from "@pie/components";

import {
  analyticsAction,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  selectAuthUser
} from "../../externals";
import { determineRoleHome } from "../../routes/routes";
import { DEFAULT_RETURN_TEXT, PieCrustSettings } from "../../constants";

export type NotAuthorizedProps = {
  returnText?: string;
  redirectTo?: string;
};

export const NotAuthorized: React.FC<NotAuthorizedProps> = ({
  returnText = DEFAULT_RETURN_TEXT,
  redirectTo
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const settings = useSettings<PieCrustSettings>();
  const isPolicyEnabled = Boolean(settings?.features?.Policy);

  const authUser = useSelector(selectAuthUser);
  const roleHome = determineRoleHome(authUser, isPolicyEnabled);

  useMount(() => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.ACCESS_RESTRICTED_PAGE,
        type: "Error",
        options: ["trackRoute"],
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName
        }
      })
    );
  });

  const handleBackClick = (): void =>
    redirectTo ? push(redirectTo) : push(roleHome);

  return (
    <AccessRestrictedPage
      returnText={returnText}
      onBackClick={handleBackClick}
    />
  );
};
