import { createSelector } from "@reduxjs/toolkit";
import { AgencyRulesState } from "./types";
import { PieCrustApplicationState, PieCrustStateKey } from "../../types";

const selectAgencyRulesState = (
  state: PieCrustApplicationState
): AgencyRulesState => state[PieCrustStateKey.AGENCY_RULES];

export const selectCanUseApiKeys = createSelector(
  selectAgencyRulesState,
  state => state.canUseApiKeys
);
