import * as React from "react";
import { PieCrustContext, PieCrustFunctions } from ".";

export const PieCrustProvider: React.FC = ({ children }) => {
  const [pieCrustFunctions, setPieCrustFunctions] = React.useState<
    PieCrustFunctions
  >({
    onAgencyChange: () => Promise.resolve(true)
  });
  return (
    <PieCrustContext.Provider
      value={{
        pieCrustFunctions,
        setPieCrustFunctions
      }}
    >
      {children}
    </PieCrustContext.Provider>
  );
};
