import * as React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import { faFileSearch } from "@fortawesome/pro-duotone-svg-icons";

export const AppetiteCheckerIcon: React.FC<Partial<
  FontAwesomeIconProps
>> = props => <FontAwesomeIcon icon={faFileSearch} {...props} />;
