import { getActionName } from "../utils";
import {
  AnalyticsActionCreator,
  AnalyticsEvent,
  analyticsGenericActionType,
  convertToAnalyticsEvent,
  PieCrustApplicationState
} from "../../../externals";

export const genericEventMap = {
  [analyticsGenericActionType]: (
    action: ReturnType<AnalyticsActionCreator>,
    state: PieCrustApplicationState
  ): AnalyticsEvent => {
    const { actionName, type, options, ...rest } = action.payload;
    return convertToAnalyticsEvent(
      state,
      {
        actionName: getActionName(actionName, type),
        ...rest
      },
      options
    );
  }
};
