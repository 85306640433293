import { createSelector } from "@reduxjs/toolkit";
import {
  Agency,
  selectAvailableAgenciesCollection,
  selectSelectedAgencyId
} from "../../externals";

export const selectSelectedAgencyAsOption = createSelector(
  selectAvailableAgenciesCollection,
  selectSelectedAgencyId,
  (allAgencies, selectedAgencyId) => {
    // The selected agency can be in the collection twice.  The first is just id/name, the second is the full object.
    // see getAvailableAgenciesSuccessful reducer function for more info.
    // we prefer the second instance, because it has the agencyCode.  but we can use the first one otherwise.
    // TODO: replace this with a single findLast once typescript is upgraded to v5+
    const matchingAgencies = allAgencies.filter(
      ({ id }) => id === selectedAgencyId
    );
    const agency: Agency = matchingAgencies.length
      ? matchingAgencies[matchingAgencies.length - 1]
      : ({} as Agency);
    const agencyName = agency?.internalAgencyName ?? "";
    const label = agency?.agencyCode
      ? `${agencyName} (${agency.agencyCode})`
      : agencyName;
    return [
      {
        label,
        value: selectedAgencyId ?? ""
      }
    ];
  }
);
