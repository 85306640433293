import { PieCrustApplicationState } from "../externals";

const storageKey = "state";

export const loadState = (): PieCrustApplicationState | undefined => {
  try {
    const serializedState = sessionStorage.getItem(storageKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = <S = PieCrustApplicationState>(
  state: Partial<S>
): void => {
  try {
    const prevState = loadState();
    const serializedState = JSON.stringify({ ...prevState, ...state });
    sessionStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};
