import { AuthUser, UserRole } from "@pie/components";
import { FilterableNavOption } from "../../externals";

export const filterNavOptions = (
  authUser: AuthUser,
  navOptions: FilterableNavOption[]
): FilterableNavOption[] =>
  navOptions.filter((option): FilterableNavOption | false => {
    if (authUser.role === UserRole.PieAdmin) {
      for (const predicate of option.predicates) {
        if (predicate(authUser)) {
          return option;
        }
      }
    } else if (option.roles.includes(authUser.role as UserRole)) {
      return option;
    }
    return false;
  });
