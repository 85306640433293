import React, { useRef, useEffect } from "react";
import { useLayoutStyles } from "./styles";
import { Grid } from "@material-ui/core";

import { Sidebar } from "../Sidebar/Sidebar";
import { TopBar } from "../TopBar/TopBar";

export interface LayoutProps {
  header?: string;
  pathname: string;
}

export const Layout: React.FC<LayoutProps> = ({ pathname, children }) => {
  const { stepContent } = useLayoutStyles();
  const pathnameRef = useRef<string | null>(null);
  const childWrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (pathname !== pathnameRef.current) {
      pathnameRef.current = pathname;
      if (childWrapperRef.current !== null) {
        childWrapperRef.current.scrollTop = 0;
      }
    }
  });

  return (
    <Grid container direction="row" wrap="nowrap">
      <Sidebar id="sidebar" />
      <Grid container item xs direction="column">
        <TopBar />
        <Grid ref={childWrapperRef} item xs className={stepContent}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
