import { createSlice } from "@reduxjs/toolkit";
import { Store } from "redux";
import { ConnectionState, PieCrustStateKey } from "../../externals";

const initialState: ConnectionState = true;
const connection = createSlice({
  name: PieCrustStateKey.CONNECTION,
  initialState,
  reducers: {
    setTrue: (): ConnectionState => true,
    setFalse: (): ConnectionState => false
  }
});

const connectionActions = connection.actions;

export const startConnectionListeners = (store: Store): void => {
  window.addEventListener("offline", () => {
    store.dispatch(connectionActions.setFalse());
  });
  window.addEventListener("online", () => {
    store.dispatch(connectionActions.setTrue());
  });
};
export default connection.reducer;
