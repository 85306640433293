import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "@pie/utils";
import { NotFoundPage, useSettings } from "@pie/components";

import {
  analyticsAction,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  selectAuthUser
} from "../../externals";
import { determineRoleHome } from "../../routes/routes";
import { DEFAULT_RETURN_TEXT, PieCrustSettings } from "../../constants";
import { redirectWithoutSPARouting } from "../../utils/routingUtils";

export type NotFoundProps = {
  returnText?: string;
  redirectTo?: string;
};

export const NotFound: React.FC<NotFoundProps> = ({
  returnText = DEFAULT_RETURN_TEXT,
  redirectTo
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const settings = useSettings<PieCrustSettings>();
  const migratedUis = (settings?.features?.migratedUis || []) as string[];
  const isPolicyEnabled = Boolean(settings?.features?.Policy);

  const authUser = useSelector(selectAuthUser);
  const roleHome = determineRoleHome(authUser, isPolicyEnabled);

  const isMigratedRoute = migratedUis.find(route =>
    window.location.pathname.startsWith(`/${route}`)
  );
  const hasBeenRedirectedBefore = window.location.href.includes("redirect=1");
  const shouldRedirect = isMigratedRoute && !hasBeenRedirectedBefore;

  useMount(() => {
    if (!shouldRedirect) {
      dispatch(
        analyticsAction({
          actionName: AnalyticsClickName.NOT_FOUND_PAGE,
          type: "Error",
          options: ["trackRoute"],
          properties: {
            [AnalyticsInternalProperty.SOURCE]: AppName
          }
        })
      );
    }
  });

  if (shouldRedirect) {
    redirectWithoutSPARouting();
    return null;
  }

  const handleBackClick = (): void =>
    redirectTo ? push(redirectTo) : push(roleHome);

  return <NotFoundPage returnText={returnText} onBackClick={handleBackClick} />;
};
