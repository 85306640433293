/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

type OnAgencyChange = () => Promise<boolean>;
export interface PieCrustFunctions {
  onAgencyChange: OnAgencyChange;
}

export interface IPieCrustContext {
  setPieCrustFunctions: React.Dispatch<React.SetStateAction<PieCrustFunctions>>;
  pieCrustFunctions: PieCrustFunctions;
}

const defaultPieCrustContext: IPieCrustContext = {
  setPieCrustFunctions: () => null,
  pieCrustFunctions: {
    onAgencyChange: () => Promise.resolve(true)
  }
};

// Using tricks from apollographql so we can have multiple versions of @pie/components in the page
// https://github.com/apollographql/apollo-client/blob/master/src/react/context/ApolloContext.ts
// Comment below from apollo
// If Symbol's aren't available, we'll use a fallback string as the context
// property (we're looking at you, IE11).
const contextSymbol =
  typeof Symbol === "function" && Symbol.for
    ? Symbol.for("__PIE_CRUST_CONTEXT__")
    : "__PIE_CRUST_CONTEXT__";

export const resetPieCrustContext = (): any => {
  Object.defineProperty(window, contextSymbol, {
    value: React.createContext<IPieCrustContext>(defaultPieCrustContext),
    enumerable: false,
    configurable: true,
    writable: false
  });
};

export const getPieCrustContext = (): React.Context<IPieCrustContext> => {
  if (!(window as any)[contextSymbol]) {
    resetPieCrustContext();
  }
  return (window as any)[contextSymbol];
};

// PieCrustContext Component
export const PieCrustContext = getPieCrustContext();

export const usePieCrustContext = (): IPieCrustContext =>
  React.useContext(getPieCrustContext());
