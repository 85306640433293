import { ICognitoStorage } from "amazon-cognito-identity-js";

const MEMORY_KEY_PREFIX = "@pieAuth:";
export const COGNITO_COOKIE_PREFIX = "CognitoIdentityServiceProvider";
let dataMemory: Record<string, string> = {};

const isIdToken = (key: string) => key.includes("idToken");
const isAccessToken = (key: string) => key.includes("accessToken");
const isRefreshToken = (key: string) => key.includes("refreshToken");

const writeCookie = (
  type: "idToken" | "accessToken" | "refreshToken",
  value: string,
  expires: Date
) => {
  if (debugEnabled) {
    console.log(`SETTING COOKIE `, {
      type,
      value,
      expires: expires.toUTCString()
    });
  }
  document.cookie = `${COGNITO_COOKIE_PREFIX}.${type}=${value}; expires=${expires.toUTCString()}; path=/`;
};

const debugEnabled = window.location.search.includes("DEBUG");

/**
 * This is used to set a specific item in storage
 */
function setItem(key: string, value: string): string {
  if (debugEnabled) {
    console.log(`SET ITEM ${key}, ${value}`);
  }
  localStorage.setItem(MEMORY_KEY_PREFIX + key, value);
  if (isIdToken(key)) {
    const date = new Date();

    // Set it expire in 1 day
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    writeCookie("idToken", value, date);
  }
  if (isAccessToken(key)) {
    const date = new Date();

    // Set it expire in 1 day
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    writeCookie("accessToken", value, date);
  }
  if (isRefreshToken(key)) {
    const date = new Date();

    // Set it expire in 1 day
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    writeCookie("refreshToken", value, date);
  }
  dataMemory[key] = value;
  return dataMemory[key];
}

/**
 * This is used to get a specific key from storage
 */
function getItem(key: string): string | null {
  if (debugEnabled) {
    console.log(`GET ITEM ${key}`);
  }
  const value = localStorage.getItem(MEMORY_KEY_PREFIX + key);
  return value;
}

/**
 * This is used to remove an item from storage
 */
function removeItem(key: string): boolean {
  if (debugEnabled) {
    console.info(`REMOVE ITEM ${key}`);
  }
  if (isIdToken(key)) {
    const date = new Date();
    date.setTime(0);
    writeCookie("idToken", "", date);
  }
  if (isAccessToken(key)) {
    const date = new Date();
    date.setTime(0);
    writeCookie("accessToken", "", date);
  }
  if (isRefreshToken(key)) {
    const date = new Date();
    date.setTime(0);
    writeCookie("refreshToken", "", date);
  }
  localStorage.removeItem(MEMORY_KEY_PREFIX + key);
  return delete dataMemory[key];
}

/**
 * This is used to clear the storage
 */
function clear(): Record<string, string> {
  dataMemory = {};

  const keys = Object.keys(localStorage);
  keys.forEach(key => {
    if (debugEnabled) {
      console.log(`CLEAR ${key}`);
    }
    if (key.startsWith(MEMORY_KEY_PREFIX)) {
      localStorage.removeItem(key);
    }
  });
  const date = new Date();
  date.setTime(0);
  writeCookie("idToken", "", date);
  writeCookie("accessToken", "", date);
  writeCookie("refreshToken", "", date);
  return dataMemory;
}

export const CustomCognitoAuthStorage: ICognitoStorage = {
  setItem,
  getItem,
  removeItem,
  clear
};
