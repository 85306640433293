/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createMiddleware as reduxBeaconCreateMiddleware,
  LoggerExtension,
  OfflineStorageExtension,
  Target,
  EventDefinition
} from "redux-beacon";
import { Middleware, compose, Store } from "@reduxjs/toolkit";

import { genericEventMap } from "./submaps/generic";
import { authEventMap } from "./submaps/auth";
import { analyticsLogger } from "../../api/analyticsLogger";
import { AnalyticsEvent } from "../../externals/types/analytics";

// To conform to redux-beacon
// The type conversion from an action type to { [key: string]: any } is invalid,
// however conversion of anything to any is valid. Therefore when using this,
// the developer should make sure to type define the submaps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyEventMap = { [key: string]: EventDefinition<any, any, any> };

export const loggerTarget = (): Target => (events: AnalyticsEvent[]): void => {
  events.forEach(payload => analyticsLogger(payload));
};

const initialEvents = {
  ...genericEventMap,
  ...authEventMap
};

export type AddDynamicEvents = (newEvents: AnyEventMap) => void;

interface AnalyticsEnhancedMiddleware {
  addDynamicEvents: AddDynamicEvents;
  middleware: MiddlewareReturn;
}

type MiddlewareReturn = (next: any) => (action: any) => any;

export const createAnalyticsMiddleware = (
  offlineStorage: OfflineStorageExtension,
  logger?: LoggerExtension
): AnalyticsEnhancedMiddleware => {
  let store: Store;
  let appliedDynamicEvents = {};
  let middlewareRef: Middleware;

  const createMiddleware = (events: AnyEventMap): MiddlewareReturn =>
    reduxBeaconCreateMiddleware(events, loggerTarget(), {
      offlineStorage,
      logger
    });

  const addDynamicEvents: AddDynamicEvents = newEvents => {
    appliedDynamicEvents = { ...appliedDynamicEvents, ...newEvents };
    middlewareRef = createMiddleware({
      ...initialEvents,
      ...appliedDynamicEvents
    } as AnyEventMap)(store);
  };

  const middleware = (_store: Store): MiddlewareReturn => {
    store = _store;
    if (!middlewareRef) {
      middlewareRef = createMiddleware({ ...initialEvents })(store);
    }
    return (next: any) => (action: any) => compose(middlewareRef)(next)(action);
  };

  return { addDynamicEvents, middleware };
};
