import * as React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/pro-duotone-svg-icons";

export const ApiKeyIcon: React.FC<Partial<FontAwesomeIconProps>> = props => (
  <FontAwesomeIcon icon={faKey} {...props} />
);
