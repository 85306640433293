import { makeStyles, Theme } from "@material-ui/core";

export const useErrorPageStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(3.75),
      textAlign: "center"
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium
    },
    cardText: {
      fontWeight: theme.typography.fontWeightBold
    },
    cardStyle: {
      margin: theme.spacing(2.5, 0)
    },
    buttonStyle: {
      marginTop: theme.spacing(5)
    }
  }),
  { name: "ErrorPage" }
);
