import * as React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-duotone-svg-icons";

export const DashboardIcon: React.FC<Partial<FontAwesomeIconProps>> = props => (
  <FontAwesomeIcon icon={faList} {...props} />
);
