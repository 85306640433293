import { AuthUser, gql, isSales } from "@pie/components";
import { AuthRoutes, PieCrustSettings } from "../../constants";
import queryString from "query-string";
import { PPSClient } from "../../client/PPSClient";
import { SearchPoliciesResultItem } from "./types";

const buildPolicyURL = (policyNumber?: string): string => {
  const encodedPolicyNumber = (policyNumber && encodeURI(policyNumber)) || "";
  return `${AuthRoutes.POLICY}/${encodedPolicyNumber}`;
};

const findMaybePPSPolicyNumber = async (
  settings: PieCrustSettings,
  idToken: string,
  submissionId: string
) => {
  const query = gql`
    query FindPolicyNumberBySubmissionId($submissionId: String!) {
      searchPolicies(filter: { submissionID: { eq: $submissionId } }) {
        items {
          submissionID
          policyNumber
        }
      }
    }
  `;
  return await PPSClient(settings, idToken).query({
    query,
    errorPolicy: "all",
    variables: { submissionId: submissionId }
  });
};

const matchPolicy = (
  maybePoliciesMatch: SearchPoliciesResultItem[],
  submissionID: string
) =>
  maybePoliciesMatch?.find(
    (policy: SearchPoliciesResultItem) => policy.submissionID === submissionID
  );

/*
   This strategy enhances the existing redirect behavior and will become the existing redirect behavior once PolicyUI is live
   Determine if the associated submission has a corresponding policy number that was created in PPS instead of Insurity.
   If so, send the client to the PolicyUI. Otherwise, fall back onto `DefaultStrategy`
*/
export const ppsStrategy = async (
  user: AuthUser | null | undefined,
  search: string,
  settings: PieCrustSettings,
  idToken: string
): Promise<string> => {
  const searchMap = queryString.parse(search) as { submissionId: string };
  const submissionIdParam = searchMap.submissionId;

  if (!submissionIdParam) {
    return defaultStrategy(user, search);
  }

  return findMaybePPSPolicyNumber(settings, idToken, submissionIdParam).then(
    response => {
      const policiesData = response?.data?.searchPolicies
        ?.items as SearchPoliciesResultItem[];
      const ppsPolicyMatch = matchPolicy(policiesData, submissionIdParam);
      const submissionHasPPSPolicy =
        Boolean(ppsPolicyMatch) && ppsPolicyMatch?.policyNumber;

      if (submissionHasPPSPolicy) {
        return buildPolicyURL(ppsPolicyMatch?.policyNumber);
      } else {
        return defaultStrategy(user, search);
      }
    }
  );
};
/*
  If a user is sales, they should be redirected to the Agency Flow; otherwise they should be sent to UW-Rater
  If no user is present, then send them HOME.
 */
export const defaultStrategy = (
  user: AuthUser | null | undefined,
  search: string
): string => {
  if (user) {
    if (isSales(user)) {
      return `${AuthRoutes.AGENCY_FLOW}${search}`;
    } else {
      return `${AuthRoutes.UW_RATER}${search}`;
    }
  } else {
    return AuthRoutes.HOME;
  }
};
