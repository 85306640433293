import { AuthFormStatusType } from "@pie/components";

export const authFormStatusTypeToActionName = (
  status: AuthFormStatusType
): string | undefined => {
  const mapping = {
    [AuthFormStatusType.LOGIN]: "Sign in",
    [AuthFormStatusType.FORGOT_PASSWORD]: "Forgot Password",
    [AuthFormStatusType.FORGOT_EMAIL_SENT]: "Forgot Password Confirm",
    [AuthFormStatusType.SET_NEW_PASSWORD]: undefined,
    [AuthFormStatusType.RESET_PASSWORD]: undefined
  };

  return mapping[status];
};
