import {
  PieCrustApplicationState,
  OptionalAnalyticsEvent,
  AnalyticsOptionsType,
  AnalyticsEvent
} from "..";

export function convertToAnalyticsEvent<
  S extends PieCrustApplicationState = PieCrustApplicationState
>(
  { analyticsUser, authUser, router }: S,
  {
    analyticsActions = ["TrackEvent"],
    actionTime = new Date(),
    properties,
    ...rest
  }: OptionalAnalyticsEvent,
  options?: AnalyticsOptionsType[]
): AnalyticsEvent {
  const { subject, email } = authUser;
  const route =
    options && options.includes("trackRoute") && router.location.pathname;
  return {
    analyticsActions,
    actionTime,
    user: {
      email
    },
    distinctId: (analyticsUser?.session as string) ?? subject,
    // TOOD: Role needs to be updated on sign in. When that happens, delete this.
    properties: { route, ...properties, ...analyticsUser },
    ...rest
  };
}
